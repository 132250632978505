@charset "utf-8";
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  word-break: break-all;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1.2;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
body {
  font: 16px/1.4286 arial, "Hiragino Sans GB", "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif;
  color: #333;
  background-color: #fff;
}
a {
  text-decoration: none;
  cursor: pointer;
}
p {
  line-height: 1.5;
}
img {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  vertical-align: middle;
}
/* 普通文章样式 */
.article-content {
  padding-top: 10px;
}
.article-content table {
  width: auto;
  word-break: break-all;
  border-collapse: collapse;
}
.article-content table,
.article-content td,
.article-content th {
  border: 1px solid #666;
}
.article-content p,
.article-content pre {
  font-size: 14px;
  color: #333;
  line-height: 1.5;
  margin-bottom: 20px;
  text-align: left;
}
.article-content p:last-child {
  margin-bottom: 0;
}
.article-content img {
  max-width: 100%!important;
  height: auto!important;
}
.article-content h1 {
  font-size: 20px;
}
.article-content h2 {
  font-size: 18px;
}
.article-content h3 {
  font-size: 16px;
}
.article-content h4,
.article-content h5,
.article-content h6 {
  font-size: 14px;
}
.article-content h1,
.article-content h2,
.article-content h3,
.article-content h4,
.article-content h5,
.article-content h6 {
  padding: 15px 0;
  font-weight: bold;
}
.article-content ul {
  list-style: square;
  margin-left: 1em;
  padding: 10px 0;
}
.article-content a {
  color: #0b76ac;
  text-decoration: underline;
}
.article-content ol {
  list-style: decimal;
  margin-left: 1em;
  padding: 10px 0;
}
.article-content blockquote {
  padding-left: 10px;
  border-left: 3px solid #dbdbdb;
  font-size: 14px;
}
.article-content blockquote p {
  font-size: 14px;
}
* {
  -webkit-text-size-adjust: none;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: none;
}
html {
  min-height: 100%;
  height: 100%;
  overflow: auto;
}
html body {
  min-height: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
html body .page {
  flex-grow: 1;
  position: relative;
  background-color: #fff;
}
input,
button,
textarea,
select {
  outline: none;
}
input:focus,
button:focus,
textarea:focus,
select:focus {
  outline: none;
}
a {
  color: unset;
}
.block {
  display: block;
  width: 100%;
}
.text-center {
  text-align: center !important;
}
.text-right {
  text-align: right !important;
}
.over-hidden {
  overflow: hidden;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
strong {
  font-weight: bold;
}
em {
  font-style: italic;
}
i {
  display: inline-block;
  vertical-align: middle;
}
.text-limit {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  word-break: break-all;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}
.text-limit2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  word-break: break-all;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}
.text-limit3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  word-break: break-all;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}
.text-limit4 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  word-break: break-all;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}
.text-limit5 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  word-break: break-all;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}
.hidden {
  display: none !important;
}
.dis-table {
  display: table;
  width: 100%;
}
.table-cell,
.v-align {
  display: table-cell;
  vertical-align: middle;
}
.clear-fix:after {
  content: '';
  display: inline-block;
  clear: both;
  visibility: hidden;
  height: 0;
  line-height: 0;
  font-size: 0;
}
ul.clear-fix:after {
  display: list-item;
}
.visibility-hidden {
  visibility: hidden !important;
}
.scrollbar {
  position: relative;
  overflow: hidden;
}
.perfect-scrollbar-reset.ps .ps__rail-y {
  width: 10px;
  overflow: hidden;
}
.perfect-scrollbar-reset.ps .ps__rail-y .ps__thumb-y {
  right: 0;
  background-color: #fff;
}
.ps__thumb-y {
  cursor: pointer;
  width: 10px;
  right: 0;
  background-color: #dbdbdb;
  box-shadow: 0 0 5px #fff;
}
.ps__rail-y {
  opacity: 0;
  width: 10px;
  border-radius: 25px;
}
.ps__rail-y:hover .ps__thumb-y {
  background-color: #dbdbdb;
  width: 10px;
}
.ps__rail-x {
  display: none !important;
}
.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
  background-color: #ededed;
}
.icon-desc {
  font-size: 14px;
  color: #666;
  display: table;
}
.icon-desc .icon {
  font-size: 0;
  display: table-cell;
  vertical-align: middle;
  width: 25px;
}
.icon-desc .icon > i {
  vertical-align: top;
}
.icon-desc .desc {
  display: table-cell;
  vertical-align: top;
  line-height: 1.5;
}
.icon-desc + .icon-desc {
  margin-top: 20px;
}
.icon-desc-wrapper {
  overflow: hidden;
}
.icon-desc-wrapper .icon-desc {
  margin-top: 0;
  float: left;
}
.icon-desc-wrapper .icon-desc + .icon-desc {
  margin-left: 130px;
}
.icon-desc-wrapper.space-between .icon-desc {
  margin-left: 0;
}
.icon-desc-wrapper.space-between .icon-desc:first-child {
  float: left;
}
.icon-desc-wrapper.space-between .icon-desc:last-child {
  float: right;
}
.title-underline {
  overflow: hidden;
  border-bottom: 1px solid #e6e6e6;
}
.title-underline .title {
  float: left;
  font-size: 24px;
  color: #0172f5;
  height: 70px;
  border-bottom: 3px solid #0172f5;
  padding-top: 20px;
}
.summary-list {
  overflow: hidden;
}
.summary-list li {
  float: left;
  box-sizing: border-box;
  padding: 0 20px;
}
.summary-list li .title {
  line-height: 1.5;
  color: #999;
  font-size: 14px;
  margin-bottom: 15px;
}
.summary-list li .desc {
  line-height: 1.2;
  color: #fe0000;
  font-size: 24px;
}
.summary-list.reverse .title {
  margin-bottom: 0;
}
.summary-list.reverse .desc {
  margin-bottom: 10px;
}
.offcanvas {
  position: fixed;
}
.cursor-pointer {
  cursor: pointer;
}
.clear {
  clear: both;
}
.fwb {
  font-weight: bold;
}
.pull-left {
  float: left !important;
}
.pull-right {
  float: right !important;
}
.pt30 {
  padding-top: 30px !important;
}
.pb30 {
  padding-bottom: 30px !important;
}
.mr5 {
  margin-right: 5px !important;
}
.mb30 {
  margin-bottom: 30px !important;
}
.mb25 {
  margin-bottom: 25px !important;
}
.mb20 {
  margin-bottom: 20px !important;
}
.mb15 {
  margin-bottom: 15px !important;
}
.mb5 {
  margin-bottom: 5px !important;
}
.mt20 {
  margin-top: 20px !important;
}
.mt60 {
  margin-top: 60px !important;
}
.mb0 {
  margin-bottom: 0 !important;
}
.icon-quote-l {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACYAAAAkCAQAAABP/IAuAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAHdElNRQfjCQwPFjFd2OyVAAABSElEQVRIx+2VwZGCQBBFH2TAYRLAEDAEDEFDcEPQo1cD4IAhaAgagoQACXSVhKCHFdduYJBaj3wuDJ968/vXUECHJJGr5HykHbKUm6Q7dgQdqCNLABbuMoSSmCMJUGWzrkw3uT+vchC1eb17l9wkkzV6uL3belDNBI0WgQcFMHPVhyiowpeZdqD2vai8jWIbvIq8EimzYNWLas9wylY0ncmZVJkXt+jtKqI0G2+zPUAIIMvPUUBuUIdf1DOZlMRvZs28b0CQGH1kimze3IYgqULBoR8FbMz65+82BNbKrH1nC0whRVZoWKL78pEktlO8L0KJjH3y5jKny2mYiT2QzExhug1NrsrVXpg92H4YfsVjYPUoWG1h0SiYVquzyGdrSWIetJJ5bSO9Mc50FpjvUsv8BSTl3L9TFtgx/6Uvw76oCTbBJtjX9ADJGnkcUDxo0gAAAABJRU5ErkJggg==) no-repeat center;
  width: 38px;
  height: 36px;
  background-origin: content-box;
  background-size: 38px 36px;
}
.icon-quote-r {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACcAAAAkCAQAAACgPusQAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAHdElNRQfjCQwPFjVatSiMAAABZklEQVRIx+2UsVXDMBCGP/vRk0Y13gBvgDIB3oBkAjJCsoEb92EDMwH2BFE2cGo19gRQOJDoFMnkPRrey1XWnfTp/3WyErANT4SiVdpN2E/CsUn507jhbrgb7n/h7sB9M6LvC6CSs7majyl1UdhE9FNm+6twZgpnotWZTAic1VepySdw3n5xs5kYdxIn94ubFbjKw0mzXRTn3oK9b1aoUxGcd86dwNmCe2dCG9W2EONGqpMT6oi2GUUUZ3Oef49jJZwcKuOqK8WCNnxyNmMlUls4w9nS+1vXEaO10DaMYo44u+LV09YEYQ2PcuuqB0jAzih5EeWB/LJVm7P1YG2lx4/UFhgPBosAbM3Ogw2nHqdkPHirlqoOHJv2MgN6NPptVr6/S7UNwLAZxmnCgK7MaZiC0/KBeRgGqnP63ZKdwyAFZdgcR+9koX7+AEv2x403SqverY4XpeRAy1wVsnwxFsAbuVr7pS9oemMi0f+cywAAAABJRU5ErkJggg==) no-repeat center;
  width: 39px;
  height: 36px;
  background-origin: content-box;
  background-size: 39px 36px;
}
.icon-swiper-radius {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAAPklEQVQImXXJIQ6AMBBE0fVVPQUGh+d+eK7HKRCVr4YNDaGTjPkvvLtxYEOJJ15YEPlAwzrGhPMbE/YZ1D/oVnFzVpKstOMAAAAASUVORK5CYII=) no-repeat center;
  width: 6px;
  height: 6px;
  background-origin: content-box;
  background-size: 6px 6px;
}
.container {
  width: 1180px;
  margin-left: auto;
  margin-right: auto;
}
.btn {
  display: inline-block;
  width: 120px;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  border: 0;
  padding: 0;
  transition: all 0.3s;
  text-align: center;
  cursor: pointer;
}
.btn.btn-transparent {
  background-color: transparent;
  color: #fff;
  border: 1px solid #fff;
}
.btn.btn-white {
  background-color: #fff;
  color: #0172f5;
}
.btn.btn-gold {
  background-color: #fff100;
  color: #004ea2;
}
.btn.btn-gold.btn-shadow {
  box-shadow: 0 4px 26px 2px rgba(208, 197, 13, 0.3);
}
.btn.btn-blue {
  background-color: #0172f5;
  color: #fff;
}
.btn.btn-blue.btn-shadow {
  box-shadow: 0 4px 26px 2px rgba(7, 147, 210, 0.3);
}
.btn.btn-radius {
  border-radius: 25px;
}
.btn.btn-square-radius {
  border-radius: 6px;
}
.btn.btn-sm {
  width: auto;
  padding: 0 10px;
  height: 24px;
  line-height: 24px;
  font-size: 12px;
}
.btn.btn-md {
  width: 180px;
  height: 50px;
  line-height: 50px;
  font-size: 16px;
}
.btn.btn-block {
  width: 100%;
  display: block;
}
.btn.btn-square {
  border-radius: 0;
}
.btn.btn-radius-square {
  border-radius: 4px;
}
.bg-white {
  background-color: #fff !important;
}
.text-blue {
  color: #0172f5 !important;
}
.text-black {
  color: #333333 !important;
}
.text-red {
  color: #fe0000 !important;
}
.text-gray {
  color: #999 !important;
}
.text-white {
  color: #fff !important;
}
.border-bottom {
  border-bottom: 1px solid #e6e6e6 !important;
}
.border-top {
  border-top: 1px solid #e6e6e6 !important;
}
.border-left {
  border-left: 1px solid #e6e6e6 !important;
}
.border-right {
  border-right: 1px solid #e6e6e6 !important;
}
.btn-group {
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-group .btn + .btn {
  margin-left: 30px;
}
.icon-video {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #fff;
  line-height: 60px;
  text-align: center;
  position: relative;
}
.icon-video:after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -7px;
  margin-top: -15px;
  display: inline-block;
  width: 0;
  height: 0;
  border-top: 14px solid transparent;
  border-bottom: 14px solid transparent;
  border-left: 18px solid #0172f5;
}
.icon-video.icon-video-blue:after {
  border-left: 18px solid #0172f5;
}
.title-under-line.title-under-line-center .title {
  text-align: center;
}
.title-under-line.title-under-line-center .title:before {
  left: 50%;
  margin-left: -10px;
}
.title-under-line .title {
  font-size: 24px;
  line-height: 1.5;
  color: #333333;
  position: relative;
}
.title-under-line .title:before {
  content: '';
  display: inline-block;
  width: 20px;
  height: 3px;
  background-color: #0172f5;
  position: absolute;
  left: 0;
  bottom: -18px;
}
.title-under-line .desc {
  font-size: 16px;
  line-height: 1.5;
  color: #666;
  margin-top: 40px;
}
/* overlay at start */
.mfp-fade.mfp-bg {
  opacity: 0;
  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
/* overlay animate in */
.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}
/* overlay animate out */
.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}
/* content at start */
.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
/* content animate it */
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}
/* content animate out */
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}
.triangle-left:before {
  content: '';
  display: block;
  position: absolute;
  z-index: 1;
  left: -14px;
  top: 50%;
  margin-top: -7px;
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-right: 14px solid #fff;
  border-bottom: 6px solid transparent;
}
.bg-gray {
  background-color: #f5f5f5 !important;
}
.wrapper .container {
  overflow: hidden;
}
.wrapper .container .main {
  float: left;
  width: 820px;
  margin-right: 40px;
  background-color: #fff;
}
.wrapper .container aside {
  float: right;
  width: 320px;
  background-color: #fff;
}
.aside-title {
  font-size: 22px;
  background-color: #0172f5;
  padding-left: 25px;
  display: flex;
  align-items: center;
  color: #fff;
  height: 60px;
}
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}
.pagination .page-nav {
  border: 1px solid #e6e6e6;
  width: 25px;
  height: 25px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999;
}
.pagination .number {
  color: #666;
  margin-left: 15px;
  margin-right: 15px;
}
.pagination .number.on {
  color: #0172f5;
  position: relative;
}
.pagination .number.on:after {
  content: '';
  display: inline-block;
  border-bottom: 1px solid #0172f5;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}
.bread-nav {
  display: flex;
  align-items: center;
}
.bread-nav > li + li:before {
  content: '>';
  color: #999;
  font-size: 14px;
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}
.bread-nav > li a {
  display: inline-block;
  color: #333333;
  font-size: 14px;
}
.article-wrapper {
  padding: 75px 40px 105px;
  background-color: #fff;
}
.article-wrapper .meta {
  padding-bottom: 25px;
  border-bottom: 1px solid #e6e6e6;
}
.article-wrapper .meta .title {
  font-size: 32px;
  color: #333333;
  line-height: 1.5;
  text-align: center;
  margin-bottom: 25px;
}
.article-wrapper .meta .time {
  text-align: center;
  font-size: 14px;
  color: #666;
}
.no-data {
  font-size: 14px;
  color: #666;
  line-height: 1.5;
  padding: 10px 0;
  text-align: center;
}
.mfp-content video {
  width: 80%;
  max-width: 100%;
  max-height: 100%;
}
.mfp-content button.mfp-close {
  color: #fff;
  font-size: 46px;
  border: 1px solid #fff;
  border-radius: 50%;
  transition: all 0.3s;
}
.plus-quote {
  color: #0172f5;
  font-size: 46px;
}
.plus-quote:after {
  content: '+';
  font-size: 20px;
  vertical-align: top;
}
.quote-desc {
  width: 630px;
  margin: 0 auto;
  position: relative;
  padding: 30px 80px;
}
.quote-desc .icon {
  position: absolute;
}
.quote-desc .icon.icon-quote-l {
  left: 0;
  top: 0;
}
.quote-desc .icon.icon-quote-r {
  right: 0;
  bottom: 0;
}
.quote-desc .desc {
  color: #666;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 15px;
}
.quote-desc .speaker {
  color: #999;
  font-size: 14px;
  line-height: 1.2;
  text-align: right;
}
.quote-desc .speaker:before {
  content: '——';
  margin-right: 5px;
}
.page {
  margin-top: 80px;
}
.page-index {
  margin-top: -80px;
}
.page-index .banner {
  margin-bottom: 130px;
  max-height: 750px;
  height: 750px;
  overflow: hidden;
  position: relative;
  min-width: 1180px;
  background-size: cover;
  background-position: center;
}
.page-index .banner .content {
  text-align: center;
  position: absolute;
  top: 81%;
  width: 100%;
}
.page-index .banner .content .banner-title {
  font-size: 38px;
  font-weight: bold;
  color: #ffffff;
  text-shadow: 0 2px 4px rgba(21, 20, 20, 0.39);
  line-height: 1.5;
  margin-bottom: 40px;
}
.page-index .banner .content .banner-desc {
  line-height: 1.5;
  font-size: 24px;
  color: #ffffff;
  text-shadow: 0 2px 4px rgba(21, 20, 20, 0.39);
}
.page-index .product-list {
  margin: 0 -40px 150px;
  display: flex;
}
.page-index .product-list li {
  overflow: hidden;
  position: relative;
  flex-grow: 1;
  transition: all 0.3s;
}
.page-index .product-list li.active,
.page-index .product-list li:hover {
  flex-grow: 1.3;
  transform: translate3d(0, 0, 0);
}
.page-index .product-list li.active a:before,
.page-index .product-list li:hover a:before {
  background-color: rgba(0, 0, 0, 0.2);
}
.page-index .product-list li.active a .content .bottom .get-more,
.page-index .product-list li:hover a .content .bottom .get-more {
  opacity: 1;
}
.page-index .product-list li a {
  background-position: left top;
  height: 520px;
  color: #fff;
  display: block;
  padding-top: 130px;
  position: relative;
}
.page-index .product-list li a:before {
  content: '';
  display: block;
  transition: all 0.3s;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.4);
}
.page-index .product-list li a .content {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  padding: 130px 40px 30px;
}
.page-index .product-list li a .content .product-title {
  display: inline-block;
  font-size: 22px;
  line-height: 1.2;
  position: relative;
  margin-bottom: 125px;
}
.page-index .product-list li a .content .product-title:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  bottom: -20px;
  border-bottom: 2px solid #fff;
  width: 20px;
}
.page-index .product-list li a .content .product-desc {
  font-size: 16px;
  line-height: 1.5;
  width: 190px;
}
.page-index .product-list li a .content:hover .bottom .get-more {
  border-color: #fff;
}
.page-index .product-list li a .content .bottom {
  position: absolute;
  left: 40px;
  right: 40px;
  bottom: 30px;
}
.page-index .product-list li a .content .bottom .get-more {
  transition: opacity 0.8s;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  width: 76px;
  height: 28px;
  border: 1px solid #cccccc;
  font-size: 14px;
  margin-bottom: 10px;
}
.page-index .product-list li a .content .bottom .order {
  float: right;
  width: 100%;
  text-align: right;
  font-size: 42px;
  line-height: 1.2;
}
.page-index .banner1 {
  min-width: 1180px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 690px;
  overflow: hidden;
  background-color: #0172f5;
}
.page-index .banner1:hover .img img {
  transform: scale(1.05);
}
.page-index .banner1 .img {
  height: 100%;
  width: 51%;
  overflow: hidden;
  flex-shrink: 0;
  position: relative;
}
.page-index .banner1 .img img {
  transition: all 0.3s;
}
.page-index .banner1 .img:after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(51, 51, 51, 0.25);
}
.page-index .banner1 .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 80px;
  flex-grow: 1;
}
.page-index .banner1 .content .title {
  font-size: 30px;
  font-weight: bold;
  line-height: 1.5;
  color: #ffffff;
  margin-bottom: 70px;
  max-width: 460px;
}
.page-index .banner1 .content .desc {
  font-size: 20px;
  line-height: 1.5;
  color: #ffffff;
  margin-bottom: 75px;
  max-width: 460px;
}
.page-index .banner1 .content .btn {
  box-shadow: 0 1px 3px 0 rgba(40, 38, 2, 0.35);
}
.page-index .platform-info-wrapper {
  background-color: #fafafa;
  padding: 120px 0 180px;
}
.page-index .platform-info-wrapper .platform-info {
  border-radius: 6px;
  background-color: #fff;
  width: 1000px;
  margin: 0 auto;
  padding: 85px 75px 80px;
  box-shadow: 0 10px 24px 0 rgba(17, 17, 18, 0.1);
}
.page-index .platform-info-wrapper .platform-info .platform-desc {
  font-size: 20px;
  color: #333333;
  line-height: 1.5;
  margin-bottom: 55px;
  text-align: center;
}
footer .top-wrapper {
  height: 200px;
  padding-top: 30px;
  background-color: #eef2f8;
}
footer .top-wrapper > .container {
  display: flex;
  justify-content: space-between;
}
footer .top-wrapper > .container .footer-list {
  width: 950px;
  display: flex;
}
footer .top-wrapper > .container .footer-list > li:first-child {
  width: 245px;
  padding-right: 20px;
}
footer .top-wrapper > .container .footer-list > li:nth-child(2) {
  width: 220px;
  padding-right: 20px;
}
footer .top-wrapper > .container .footer-list > li .footer-list-title {
  font-size: 18px;
  line-height: 1.2;
  color: #004ea2;
  font-weight: bold;
  margin-bottom: 20px;
}
footer .top-wrapper > .container .footer-list > li .sub-footer-list > li {
  font-size: 14px;
  line-height: 1.2;
  color: #666;
}
footer .top-wrapper > .container .footer-list > li .sub-footer-list > li a {
  color: #666;
}
footer .top-wrapper > .container .footer-list > li .sub-footer-list > li + li {
  margin-top: 15px;
}
footer .top-wrapper > .container .qr-code-wrapper {
  text-align: center;
  margin-top: 5px;
}
footer .top-wrapper > .container .qr-code-wrapper .img {
  width: 105px;
  height: 105px;
  background-color: #fff;
  margin: 0 auto 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
footer .top-wrapper > .container .qr-code-wrapper .img img {
  width: 95px;
  height: 95px;
  display: flex;
  align-items: center;
  justify-content: center;
}
footer .top-wrapper > .container .qr-code-wrapper .desc {
  font-size: 12px;
  color: #999;
  line-height: 1.2;
}
footer .bottom-wrapper {
  font-size: 12px;
  color: #fff;
  background-color: #004ea2;
  height: 40px;
}
footer .bottom-wrapper a {
  color: #fff;
}
footer .bottom-wrapper > .container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.module-banner {
  color: #fff;
  position: relative;
  min-width: 1180px;
  margin-bottom: 130px;
}
.module-banner > img {
  width: 100%;
}
.module-banner .content {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(51, 51, 51, 0.27);
}
.module-banner .content .title {
  font-size: 28px;
  line-height: 1.5;
}
.module-banner .content .desc {
  margin-top: 30px;
  font-size: 18px;
  line-height: 1.5;
}
.stat {
  color: #fff;
  height: 580px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: url('../../../../img/pc/lib-bg-stat.jpg') no-repeat center;
  background-size: cover;
}
.stat > .container .stat-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.stat > .container .stat-list.stat-list-s {
  width: 885px;
  margin-left: auto;
  margin-right: auto;
}
.stat > .container .stat-list > li .data {
  margin-bottom: 20px;
  display: flex;
  align-items: baseline;
}
.stat > .container .stat-list > li .data .number,
.stat > .container .stat-list > li .data .unit-text {
  font-size: 46px;
  line-height: 1.2;
}
.stat > .container .stat-list > li .data .small {
  font-size: 16px;
  margin-left: 15px;
  line-height: 1.2;
}
.stat > .container .stat-list > li .data-desc {
  line-height: 1.2;
  font-size: 20px;
}
.stat > .container .stat-list + .btn {
  margin-top: 60px;
}
.stat > .container .project-recommend-swiper {
  margin-top: 55px;
  margin-bottom: 40px;
  color: #fff;
  padding-top: 18px;
  padding-bottom: 18px;
}
.stat > .container .project-recommend-swiper .swiper-slide .quote-desc:before,
.stat > .container .project-recommend-swiper .swiper-slide .quote-desc:after {
  content: '';
  display: inline-block;
  position: absolute;
  width: 630px;
}
.stat > .container .project-recommend-swiper .swiper-slide .quote-desc:before {
  left: -80px;
  bottom: 0;
  top: 0;
  border-left: 1px solid rgba(255, 255, 255, 0.4);
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
}
.stat > .container .project-recommend-swiper .swiper-slide .quote-desc:after {
  right: -80px;
  bottom: 0;
  top: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
  border-right: 1px solid rgba(255, 255, 255, 0.4);
}
.stat > .container .project-recommend-swiper .swiper-slide .quote-desc .icon:before {
  content: '';
  display: inline-block;
  position: absolute;
  width: 40px;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
}
.stat > .container .project-recommend-swiper .swiper-slide .quote-desc .icon.icon-quote-l {
  top: -18px;
}
.stat > .container .project-recommend-swiper .swiper-slide .quote-desc .icon.icon-quote-l:before {
  left: -79px;
  bottom: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
  top: 18px;
}
.stat > .container .project-recommend-swiper .swiper-slide .quote-desc .icon.icon-quote-r {
  bottom: -18px;
}
.stat > .container .project-recommend-swiper .swiper-slide .quote-desc .icon.icon-quote-r:before {
  right: -79px;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
  bottom: 18px;
}
.stat > .container .project-recommend-swiper .swiper-slide .quote-desc .desc {
  color: #fff;
}
.stat > .container .project-recommend-swiper .swiper-slide .quote-desc .speaker {
  color: #fff;
}
.stat > .container .btn {
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.video-wrapper {
  background-color: #f7f9fa;
  padding-top: 130px;
  padding-bottom: 135px;
}
.video-wrapper .title {
  font-size: 30px;
  line-height: 1.5;
  color: #333333;
  margin-bottom: 80px;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.video-wrapper .video {
  cursor: pointer;
  margin-left: 80px;
  margin-right: 80px;
  height: 480px;
  position: relative;
  box-shadow: 0 8px 24px 2px rgba(1, 24, 35, 0.2);
  border-radius: 6px;
  overflow: hidden;
}
.video-wrapper .video:before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgba(51, 51, 51, 0.25);
}
.video-wrapper .video .icon-video {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -30px;
  margin-top: -30px;
  z-index: 2;
}
.video-wrapper .video img {
  transition: all 3s;
}
.video-wrapper .video:hover img {
  transform: scale(1.1);
}
.page-lib .module-banner {
  margin-bottom: 70px;
}
.page-lib .lib-recommend-swiper {
  margin-bottom: 75px;
  min-width: 1180px;
  padding-top: 70px;
  padding-bottom: 70px;
  margin-left: -80px;
}
.page-lib .lib-recommend-swiper .swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-lib .lib-recommend-swiper .swiper-slide .img {
  margin-right: 85px;
  border-radius: 6px;
  flex-shrink: 0;
  width: 820px;
  overflow: hidden;
  box-shadow: 0 3px 32px 0 rgba(3, 73, 149, 0.36);
}
.page-lib .lib-recommend-swiper .swiper-slide .content {
  width: 350px;
}
.page-lib .lib-recommend-swiper > .swiper-pagination-bullets {
  bottom: 0;
}
.page-lib .lib-recommend-swiper .swiper-pagination-bullet {
  width: 13px;
  height: 13px;
}
.page-lib .peroration {
  height: 505px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.page-lib .peroration .title {
  font-size: 30px;
  line-height: 1.5;
  color: #333333;
  margin-bottom: 45px;
  text-align: center;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.mfp-content {
  text-align: center;
}
.page-move .info-swiper-wrapper {
  margin-bottom: 130px;
  display: flex;
  align-items: center;
  position: relative;
}
.page-move .info-swiper-wrapper .info-swiper {
  margin-left: -70px;
  height: 520px;
  width: 810px;
}
.page-move .info-swiper-wrapper .info-list {
  height: 520px;
  display: flex;
  flex-direction: column;
  width: 440px;
  border-right: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
}
.page-move .info-swiper-wrapper .info-list.info-list-3 > li {
  height: 33.33%;
}
.page-move .info-swiper-wrapper .info-list > li {
  transition: all 0.3s;
  border-top: 1px solid #e6e6e6;
  flex-grow: 1;
  position: relative;
  padding-left: 35px;
  padding-right: 20px;
  display: flex;
  align-items: center;
}
.page-move .info-swiper-wrapper .info-list > li .content {
  display: flex;
}
.page-move .info-swiper-wrapper .info-list > li .content .number {
  flex-shrink: 0;
  font-size: 20px;
  line-height: 1.5;
  color: #0172f5;
  font-style: italic;
}
.page-move .info-swiper-wrapper .info-list > li .content .desc {
  font-size: 20px;
  line-height: 1.5;
  color: #666;
  margin-left: 15px;
}
.page-move .info-swiper-wrapper .mask-content {
  height: 160px;
  transition: all 0.3s;
  position: absolute;
  width: 440px;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 35px 20px 35px 35px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0 2px 18px 0 rgba(3, 73, 149, 0.2);
  z-index: -1;
  opacity: 0;
}
.page-move .info-swiper-wrapper .mask-content:before {
  content: '';
  display: block;
  position: absolute;
  z-index: 1;
  left: -14px;
  top: 50%;
  margin-top: -7px;
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-right: 14px solid #fff;
  border-bottom: 6px solid transparent;
}
.page-move .info-swiper-wrapper .mask-content.active {
  opacity: 1;
  z-index: 2;
}
.page-move .info-swiper-wrapper .mask-content .title {
  min-height: 36px;
}
.recommend-swiper-wrapper {
  margin-bottom: 130px;
  display: flex;
  align-items: center;
  position: relative;
}
.recommend-swiper-wrapper .recommend-swiper {
  margin-left: -70px;
  height: 520px;
  width: 810px;
}
.recommend-swiper-wrapper .recommend-swiper .swiper-slide {
  background-color: #fff;
}
.recommend-swiper-wrapper .recommend-list {
  height: 520px;
  display: flex;
  flex-direction: column;
  width: 440px;
  border-right: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
}
.recommend-swiper-wrapper .recommend-list > li {
  transition: all 0.3s;
  border-top: 1px solid #e6e6e6;
  flex-grow: 1;
  position: relative;
  padding-left: 35px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.recommend-swiper-wrapper .recommend-list > li:before {
  content: '';
  display: block;
  position: absolute;
  z-index: 1;
  left: -14px;
  top: 50%;
  margin-top: -7px;
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-right: 14px solid #fff;
  border-bottom: 6px solid transparent;
}
.recommend-swiper-wrapper .recommend-list > li:before {
  left: 0;
  transition: all 0.3s;
}
.recommend-swiper-wrapper .recommend-list > li .title {
  margin-bottom: 10px;
  transition: all 0.3s;
}
.recommend-swiper-wrapper .recommend-list > li .desc:before {
  transition: all 0.3s;
  opacity: 0;
}
.recommend-swiper-wrapper .recommend-list > li:hover,
.recommend-swiper-wrapper .recommend-list > li.active {
  box-shadow: 0 2px 18px 0 rgba(3, 73, 149, 0.2);
}
.recommend-swiper-wrapper .recommend-list > li:hover:before,
.recommend-swiper-wrapper .recommend-list > li.active:before {
  left: -14px;
}
.recommend-swiper-wrapper .recommend-list > li:hover .desc:before,
.recommend-swiper-wrapper .recommend-list > li.active .desc:before {
  opacity: 1;
}
.peroration-common {
  height: 510px;
}
.peroration-common > .container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.peroration-common > .container .content .title {
  font-size: 30px;
  line-height: 1.5;
  margin-bottom: 20px;
  color: #333333;
}
.peroration-common > .container .content .desc {
  font-size: 16px;
  line-height: 1.5;
  color: #666;
}
.peroration-common > .container .qr-code-wrapper {
  margin-left: 90px;
  text-align: center;
}
.peroration-common > .container .qr-code-wrapper .qr-code {
  width: 150px;
  height: 150px;
  padding: 10px;
  box-shadow: 0 2px 14px -3px rgba(0, 0, 0, 0.33);
  margin-bottom: 16px;
}
.peroration-common > .container .qr-code-wrapper .qr-code img {
  width: 100%;
  height: 100%;
}
.peroration-common > .container .qr-code-wrapper .desc {
  font-size: 14px;
  color: #999;
}
.page-news .module-banner {
  margin-bottom: 0;
}
.page-news .category > .container {
  height: 70px;
  background-color: #fff;
}
.page-news .category > .container .category-list {
  display: flex;
  align-items: center;
  height: 100%;
}
.page-news .category > .container .category-list > li {
  height: 100%;
  position: relative;
}
.page-news .category > .container .category-list > li + li {
  margin-left: 85px;
}
.page-news .category > .container .category-list > li a {
  font-size: 16px;
  display: flex;
  align-items: center;
  height: 100%;
  color: #666;
}
.page-news .category > .container .category-list > li:after {
  content: '';
  transition: all 0.3s;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  width: 0;
  background-color: #0172f5;
  border-bottom: 2px solid #0172f5;
  display: inline-block;
  opacity: 0;
}
.page-news .category > .container .category-list > li.active a,
.page-news .category > .container .category-list > li:hover a {
  color: #0172f5;
}
.page-news .category > .container .category-list > li.active a:after,
.page-news .category > .container .category-list > li:hover a:after {
  opacity: 1;
}
.page-news .category > .container .category-list > li.active:after,
.page-news .category > .container .category-list > li:hover:after {
  width: 100%;
  opacity: 1;
}
.page-news .wrapper {
  padding-top: 30px;
  padding-bottom: 130px;
}
.page-news .wrapper .main {
  padding-bottom: 90px;
}
.page-news .wrapper .main .news-list {
  padding-bottom: 80px;
}
.page-news .wrapper .main .news-list > li {
  padding: 30px 25px;
  border-bottom: 1px solid #e6e6e6;
}
.page-news .wrapper .main .news-list > li:hover a .img img {
  transform: scale(1.1);
}
.page-news .wrapper .main .news-list > li:hover a .content .title {
  color: #0172f5;
}
.page-news .wrapper .main .news-list > li a {
  display: flex;
  height: 186px;
}
.page-news .wrapper .main .news-list > li a .img {
  flex-shrink: 0;
  width: 280px;
  height: 186px;
  overflow: hidden;
  text-align: center;
  line-height: 186px;
  font-size: 0;
  margin-right: 30px;
}
.page-news .wrapper .main .news-list > li a .img img {
  transition: all 0.3s;
}
.page-news .wrapper .main .news-list > li a .content {
  padding-top: 25px;
  min-width: 0;
}
.page-news .wrapper .main .news-list > li a .content .title {
  font-size: 18px;
  color: #333333;
  font-weight: bold;
  line-height: 1.5;
  margin-bottom: 20px;
  height: 55px;
}
.page-news .wrapper .main .news-list > li a .content .time {
  font-size: 14px;
  color: #999;
  line-height: 1.5;
  margin-bottom: 20px;
}
.page-news .wrapper .main .news-list > li a .content .desc {
  line-height: 1.5;
  font-size: 14px;
  color: #666;
}
aside {
  padding-bottom: 90px;
}
aside .news-recommend-list {
  padding: 0 25px;
}
aside .news-recommend-list > li {
  padding: 30px 0;
  border-bottom: 1px dashed #e6e6e6;
}
aside .news-recommend-list > li a {
  display: block;
}
aside .news-recommend-list > li a .title {
  font-size: 16px;
  color: #666;
  line-height: 1.5;
  margin-bottom: 15px;
}
aside .news-recommend-list > li a .desc {
  font-size: 14px;
  color: #999;
  line-height: 1.5;
}
aside .news-recommend-list > li a:hover .title {
  color: #0172f5;
}
.page-news-detail {
  padding-bottom: 130px;
}
.page-news-detail .bread-nav {
  height: 65px;
}
.page-about {
  padding-bottom: 160px;
}
.page-about .module-banner {
  margin-bottom: 110px;
}
.page-about .title-under-line .title {
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.page-about .about-swiper-wrapper {
  margin: 0 -40px;
  overflow: hidden;
}
.page-about .about-swiper-wrapper .about-swiper {
  width: 900px;
  height: 480px;
}
.page-about .about-swiper-wrapper .about-swiper .swiper-slide {
  text-align: center;
  overflow: hidden;
  font-size: 18px;
  background: #fff;
  border-radius: 6px;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(0.75);
  transition: transform 0.3s;
}
.page-about .about-swiper-wrapper .about-swiper .swiper-slide.swiper-slide-prev {
  left: 228px !important;
  transition: left 0.3s linear 0.3s;
}
.page-about .about-swiper-wrapper .about-swiper .swiper-slide.swiper-slide-prev:before,
.page-about .about-swiper-wrapper .about-swiper .swiper-slide.swiper-slide-prev:after {
  content: '';
  position: absolute;
  left: 506px;
  z-index: 2;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAAPklEQVQImXXJIQ6AMBBE0fVVPQUGh+d+eK7HKRCVr4YNDaGTjPkvvLtxYEOJJ15YEPlAwzrGhPMbE/YZ1D/oVnFzVpKstOMAAAAASUVORK5CYII=) no-repeat center;
  width: 6px;
  height: 6px;
  background-origin: content-box;
  background-size: 6px 6px;
}
.page-about .about-swiper-wrapper .about-swiper .swiper-slide.swiper-slide-prev:before {
  top: 0;
}
.page-about .about-swiper-wrapper .about-swiper .swiper-slide.swiper-slide-prev:after {
  bottom: 0;
  transform: rotate(-90deg);
}
.page-about .about-swiper-wrapper .about-swiper .swiper-slide.swiper-slide-next {
  transition: left 0.3s linear 0.3s;
  left: -228px !important;
}
.page-about .about-swiper-wrapper .about-swiper .swiper-slide.swiper-slide-next:before,
.page-about .about-swiper-wrapper .about-swiper .swiper-slide.swiper-slide-next:after {
  content: '';
  position: absolute;
  right: 506px;
  z-index: 2;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAAPklEQVQImXXJIQ6AMBBE0fVVPQUGh+d+eK7HKRCVr4YNDaGTjPkvvLtxYEOJJ15YEPlAwzrGhPMbE/YZ1D/oVnFzVpKstOMAAAAASUVORK5CYII=) no-repeat center;
  width: 6px;
  height: 6px;
  background-origin: content-box;
  background-size: 6px 6px;
}
.page-about .about-swiper-wrapper .about-swiper .swiper-slide.swiper-slide-next:before {
  top: 0;
  transform: rotate(90deg);
}
.page-about .about-swiper-wrapper .about-swiper .swiper-slide.swiper-slide-next:after {
  bottom: 0;
  transform: rotate(180deg);
}
.page-about .about-swiper-wrapper .about-swiper .swiper-slide-active,
.page-about .about-swiper-wrapper .about-swiper .swiper-slide-duplicate-active {
  transform: scale(1);
  z-index: 3;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 20px 40px 0 rgba(1, 8, 12, 0.24);
}
.page-about .read-union {
  display: flex;
  align-items: center;
  margin-bottom: 120px;
}
.page-about .read-union .img {
  width: 580px;
  height: 390px;
  overflow: hidden;
  text-align: center;
  line-height: 390px;
  font-size: 0;
  margin-right: 65px;
  flex-shrink: 0;
  border-radius: 6px;
}
.page-about .read-union .content {
  height: 100%;
  font-size: 16px;
  color: #666;
  line-height: 1.9;
}
.page-about .peroration .title-under-line {
  margin-bottom: 55px;
}
.page-help .module-banner {
  margin-bottom: 0;
}
.page-help .main {
  padding-top: 90px;
  padding-bottom: 150px;
}
.page-help .main .module-list {
  margin-right: -30px;
  display: flex;
  flex-wrap: wrap;
}
.page-help .main .module-list > li {
  width: 575px;
  height: 300px;
  margin-right: 30px;
  margin-bottom: 30px;
  display: flex;
  padding: 0 25px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.12);
}
.page-help .main .module-list > li .img {
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-shrink: 0;
}
.page-help .main .module-list > li .img img {
  max-width: 100%;
}
.page-help .main .module-list > li .content {
  padding-left: 60px;
  padding-top: 60px;
  width: 275px;
}
.page-help .main .module-list > li .content .title {
  font-size: 24px;
  color: #333333;
  margin-bottom: 15px;
  font-weight: bold;
  line-height: 1.5;
}
.page-help .main .module-list > li .content .sub-module-list {
  font-size: 16px;
}
.page-help .main .module-list > li .content .sub-module-list > li {
  line-height: 1.5;
  margin-bottom: 5px;
}
.page-help .main .module-list > li .content .sub-module-list > li:hover a {
  color: #0172f5;
}
.page-help .main .module-list > li .content .sub-module-list > li a {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  color: #666;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.model-mask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(51, 51, 51, 0.25);
  z-index: 10;
  display: none;
}
.model {
  z-index: 11;
  display: none;
  position: fixed;
  left: 50%;
  top: 50%;
  width: 300px;
  height: 300px;
  background-color: #fff;
}
.model ::-webkit-scrollbar {
  width: 6px;
  height: 9px;
  border-radius: 5px;
}
.model ::-webkit-scrollbar-button,
.model ::-webkit-scrollbar-track {
  display: none;
}
.model ::-webkit-scrollbar-track-piece {
  background-color: #F2F2F2;
  border-radius: 5px;
}
.model ::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 5px;
}
.model.active {
  transform: translate(-50%, -50%);
}
.model .model-header {
  padding: 15px;
  border-bottom: 1px solid #f5f5f5;
}
.model .model-header h4 {
  font-size: 24px;
}
.model .model-content {
  height: 85%;
  flex-grow: 1;
  border-bottom: 1px solid #f5f5f5;
  padding: 15px;
  overflow-y: auto;
}
.model .model-content .content {
  font-size: 16px;
}
.model .model-footer {
  padding: 10px;
}
.model.model-lg {
  width: 800px;
  height: 80%;
}
.count {
  white-space: nowrap;
  display: inline-block;
}
@media screen and (max-width: 1376px) {
  .page-index .banner {
    margin-bottom: 80px;
  }
  .page-index .banner .content .banner-title {
    font-size: 28px;
  }
  .page-index .banner .content .banner-desc {
    font-size: 18px;
  }
  .page-index .product-list {
    margin-bottom: 100px;
  }
  .page-index .banner1 .content {
    padding-left: 40px;
  }
  .page-index .banner1 .content .title {
    font-size: 24px;
    margin-bottom: 35px;
  }
  .page-index .banner1 .content .desc {
    font-size: 18px;
    margin-bottom: 30px;
  }
  .btn.btn-md {
    width: 160px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
  }
}
